<template>
  <div class="flex-form">
    <el-form
      ref="categoryForm"
      v-loading="loading"
      :model="categoryForm"
      label-width="160px"
      :rules="rules"
      autocomplete="off"
    >
      <el-form-item>
        <h2 v-if="isEditMode">
          Edit Category
        </h2>
        <h2 v-else-if="!isEditMode">
          Add Category
        </h2>
      </el-form-item>

      <el-form-item
        v-if="isEditMode"
        label="Uid"
      >
        {{ categoryUid }}
      </el-form-item>

      <el-form-item
        v-if="isEditMode"
        label="Active"
        prop="active"
      >
        <el-switch
          v-if="isEditMode"
          v-model="categoryForm.isActive"
        />
      </el-form-item>

      <el-form-item
        label="Name"
        prop="name"
      >
        <el-input v-model="categoryForm.name" />
      </el-form-item>

      <el-form-item
        label="Relative Url"
        prop="relativeUrl"
      >
        <el-input v-model="categoryForm.relativeUrl" />
      </el-form-item>

      <el-form-item
        label="Header Text"
        prop="headerText"
      >
        <el-input
          v-model="categoryForm.headerText"
          type="textarea"
          resize="none"
          :autosize="{ minRows: 2, maxRows: 8}"
        />
      </el-form-item>

      <el-form-item
        label="Content Title"
        prop="contentTitle"
      >
        <el-input v-model="categoryForm.contentTitle" />
      </el-form-item>

      <!-- <el-form-item
        label="Content Text"
        prop="contentText"
      >
        <el-input
          v-model="categoryForm.contentText"
          type="textarea"
          resize="none"
          :autosize="{ minRows: 2, maxRows: 8}"
        />
      </el-form-item> -->

      <el-form-item
        label="Content Text"
        prop="contentText"
      >
        <html-editor
          :content="categoryForm.contentText || ''"
          :height="250"
          @change="updateEditorData"
        />
      </el-form-item>

      <el-form-item
        label="Content Text 2"
        prop="contentText2"
      >
        <html-editor
          :content="categoryForm.contentText2 || ''"
          :height="250"
          @change="updateEditorData2"
        />
      </el-form-item>

      <el-form-item
        label="Meta Title"
        prop="metaTitle"
      >
        <el-input v-model="categoryForm.metaTitle" />
      </el-form-item>

      <el-form-item
        label="Meta Description"
        prop="metaDescription"
      >
        <el-input
          v-model="categoryForm.metaDescription"
          type="textarea"
          resize="none"
          :autosize="{ minRows: 2, maxRows: 8}"
        />
      </el-form-item>

      <el-form-item
        label="Position"
        prop="position"
      >
        <el-input v-model="categoryForm.position" />
      </el-form-item>

      <el-form-item
        label="Background Color"
        prop="bgColor"
      >
        <el-color-picker v-model="categoryForm.bgColor" />
      </el-form-item>

      <el-form-item
        label="Circle Color"
        prop="circleColor"
      >
        <el-color-picker v-model="categoryForm.circleColor" />
      </el-form-item>

      <el-form-item
        label="Text Color"
        prop="circleColor"
      >
        <el-color-picker v-model="categoryForm.textColor" />
      </el-form-item>

      <el-form-item
        label="Icon"
      >
        <upload-media
          ref="uploadIcon"
          :image-url="iconUrl"
          @success="handleIconUploadSuccess"
        />
      </el-form-item>

      <el-form-item
        label="Logo"
      >
        <upload-media
          ref="uploadLogo"
          :image-url="logoUrl"
          @success="handleImageUploadSuccess"
        />
      </el-form-item>

      <el-form-item class="flex-form__action-buttons">
        <el-button
          type="primary"
          :loading="saving"
          @click="onSubmit"
        >
          Save
        </el-button>

        <el-button @click="onCancel">
          Cancel
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  getCategory,
  updateCategory,
  createCategory,
} from '@/api/category';
import { cloneDeep } from 'lodash';
import { makeUpdateObj } from '@/utils';
import UploadMedia from '@/components/UploadMedia';

export default {
  name: 'CategoryDetails',
  components: { UploadMedia },
  props: {
    isEditMode: {
      default: false,
      type: Boolean,
    },
    categoryUid: {
      default: null,
      type: String,
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      saving: false,
      categoryForm: {
        name: '',
        headerText: '',
        contentTitle: '',
        contentText: '',
        contentText2: '',
        metaTitle: '',
        metaDescription: '',
        position: '',
        bgColor: '',
        circleColor: '',
        iconMediaUid: null,
        logoMediaUid: null,
        isActive: true,
      },
      category: {},
      rules: {
        name: [{
            required: true,
            trigger: 'blur',
            message: 'Name is required',
          }],
        relativeUrl: [{
          required: true,
          trigger: 'blur',
          message: 'Relative Url is required',
        }],
        contentTitle: [{
            required: true,
            trigger: 'blur',
            message: 'Title is required',
          }],
        headerText: [{
          required: true,
          trigger: 'blur',
          message: 'Header is required',
        }],
        position: [{
          required: true,
          trigger: 'blur',
          message: 'Position is required',
        }],
      },
    };
  },
  computed: {
    iconUrl() {
      return this.categoryForm?.media?.icon?.image?.location;
    },
    logoUrl() {
      return this.categoryForm?.media?.logo?.image?.location;
    },
  },
  watch: {
    categoryUid(val) {
      if (val) {
        this.getCategory(val);
      } else {
        this.resetForm();
      }
    },
  },
  created() {
    if (this.isEditMode) {
      this.getCategory(this.categoryUid);
    }
  },
  methods: {
    async getCategory(categoryUid) {
      try {
        this.resetForm();
        this.loading = true;
        const data = await getCategory({ categoryUid });

        this.category = cloneDeep(data.category);
        this.categoryForm = data.category;
        this.loading = false;
      } catch (e) {
        this.loading = false;

        this.onCancel();
      }
    },
    async onEditSubmit() {
      this.$refs.categoryForm.validate(async (valid) => {
        if (valid) {
          const updatedCategory = makeUpdateObj(this.category, this.categoryForm);
          if (Object.keys(updatedCategory).length > 0) {
            try {
              this.saving = true;
              updatedCategory.categoryUid = this.categoryUid;

              await updateCategory(updatedCategory);

              this.$message({
                type: 'success',
                message: 'Data has been saved successfully',
              });

              await this.getCategory(this.categoryUid);
              this.saving = false;

              this.$emit('update');
            } catch (e) {
              this.saving = false;
            }
          }

          this.close();
        }
      });
    },
    async onAddSubmit() {
      this.$refs.categoryForm.validate(async (valid) => {
        if (valid) {
          try {
            this.saving = true;
            await createCategory(this.categoryForm);

            this.$message({
              type: 'success',
              message: 'Category has been added successfully',
            });

            this.resetForm();
            this.saving = false;

            this.$emit('update');
            this.close();
          } catch (e) {
            this.saving = false;
          }
        }
      });
    },
    async onSubmit() {
      if (this.isEditMode) {
        await this.onEditSubmit();
      } else {
        await this.onAddSubmit();
      }
    },
    resetForm() {
      this.$nextTick(() => {
        this.$refs.categoryForm.resetFields();
        this.$refs.uploadIcon.reset();
        this.$refs.uploadLogo.reset();
      });
    },
    onCancel() {
      this.close();
    },
    close() {
      this.$emit('update:visible', false);
    },
    handleIconUploadSuccess(data) {
      this.categoryForm.iconMediaUid = data.mediaUid;
    },
    handleImageUploadSuccess(data) {
      this.categoryForm.logoMediaUid = data.mediaUid;
    },
    updateEditorData(data) {
      this.categoryForm.contentText = data;
    },
    updateEditorData2(data) {
      this.categoryForm.contentText2 = data;
    },
  },
};
</script>
